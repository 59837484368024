<template>
<div id="hongbao">
  <h3 v-if="!open" class="controls subtitle is-3 has-text-danger">活动未开启，不允许审批</h3>
  <b-tabs v-model="tabIndex" position="is-centered" type="is-boxed" size="is-medium">
    <b-tab-item label="提现申请">
      <b-message v-if="editType==='approving' && batchMessage" type="is-success" has-icon>
        {{batchMessage}}
      </b-message>
      <b-table :data="records"
        paginated backend-pagination checkable
        :is-row-checkable="(row) => row.status === 0"
        :checked-rows.sync="checkedRows"
        :per-page="perPage"
        :total="total"
        :current-page="currentPage"
        @page-change="onPageChange">
        <b-table-column #default="props" field="id" label="ID" width="40" numeric>
          {{ props.row.id }}
        </b-table-column>
        <b-table-column #default="props" field="sagiuid" label="sagiuid" width="120">
          {{ props.row.sagiuid }}
        </b-table-column>
        <b-table-column #default="props" field="money" label="金额(元)" width="120">
          {{ (props.row.money/100).toFixed(2) }}
        </b-table-column>
        <b-table-column #default="props" field="adminuid" label="adminuid" width="80">
          {{ props.row.adminuid }}
        </b-table-column>
        <b-table-column #default="props" field="createtime" label="创建日期">
          {{ formatDateTime(props.row.createtime) }}
        </b-table-column>
        <b-table-column #default="props" field="updatetime" label="更新日期">
          {{ formatDateTime(props.row.updatetime) }}
        </b-table-column>
        <b-table-column #default="props" field="note" label="原因">
          {{ props.row.note }}
        </b-table-column>
        <b-table-column #default="props" field="status" label="状态">
          <b-tag :type="statusColor(props.row.status)"> {{ statusText(props.row.status) }}</b-tag>
        </b-table-column>
        <template #bottom-left>
          <b>总计：</b>{{ total }}
        </template>
      </b-table>
      <div v-if="open && checkedRows.length > 0" class="buttons is-centered">
        <button class="button is-danger" @click="onGotoApprove">批量审批</button>
      </div>
    </b-tab-item>
    <b-tab-item label="拒绝申请" :disabled="editType !== 'approve'">
      <b-field horizontal label="选中的ID">
        <div class="is-centered">
        {{ ids.join(',') }}
        </div>
      </b-field>
      <b-field horizontal label="通过或拒绝">
        <b-radio v-model="approve" :native-value="true">通过</b-radio>
        <b-radio v-model="approve" :native-value="false">拒绝</b-radio>
      </b-field>
      <b-field horizontal v-if="!approve" label="拒绝原因">
        <b-input v-model="note" placeholder="必须填写拒绝原因"></b-input>
      </b-field>
      <button v-if="approve" class="button is-success" @click="onApproveClick()">批量通过</button>
      <button v-else :disabled="!note" class="button is-danger" @click="onApproveClick()">批量拒绝</button>
    </b-tab-item>
  </b-tabs>
</div>
</template>

<script>
import cache from '@/core/cache'
import config from '@/core/config'

export default {
  mounted () {
    this.getRecords()
  },
  computed: {
    regional () {
      return cache.getR(true)
    }
  },
  data () {
    return {
      editType: 'record',
      open: false,
      perPage: config.PER_PAGE,
      currentPage: 1,
      total: 0,
      tabIndex: 0,
      records: [
      ],
      note: null,
      checkedRows: [],
      ids: [],
      approve: true,
      batchMessage: null,
      user: cache.getUser()
    }
  },
  methods: {
    statusColor (status) {
      if (status === 0) {
        return 'is-info'
      } else if (status === 1) {
        return 'is-success'
      }
      return 'is-danger'
    },
    statusText (status) {
      if (status === 0) {
        return '审核中'
      } else if (status === 1) {
        return '已通过'
      }
      return '已拒绝'
    },
    onPageChange (page) {
      this.currentPage = page
      this.getRecords(page)
    },
    onGotoApprove () {
      for (const row of this.checkedRows) {
        this.ids.push(row.id)
      }
      this.editType = 'approve'
    },
    async getRecords (page) {
      page = page || this.currentPage || 1
      try {
        const data = await this.mjp.get2({
          url: '/hongbao/withdraw-record/',
          query: { page: page, per_page: this.perPage },
          mjpType: 'mp'
        })
        this.records = data.withdraws
        this.currentPage = data.page
        this.perPage = data.per_page
        this.total = data.total
        this.open = data.open
      } catch (error) {
        console.error(error)
      }
    },
    async batchApprove (id) {
      if (id === undefined) {
        id = this.ids.pop()
      }
      if (id === undefined) {
        this.batchMessage = null
        this.hub.alert('操作全部完成！', 5)
        this.editType = 'record'
        return
      }
      const action = this.approve ? '通过' : '拒绝'
      this.batchMessage = `正在${action}提现申请 ${id}...`
      if (this.note) {
        this.batchMessage += `拒绝原因： ${this.note}`
      }
      const data = { approve: this.approve, id, adminuid: this.user.uid, note: this.note }
      try {
        await this.mjp.post2({
          url: '/hongbao/withdraw-approve/',
          data,
          query: { r: this.regional },
          mjpType: 'mp'
        })
        this.batchApprove()
      } catch (error) {
        console.error(error)
        this.editType = 'record'
      }
    },
    async onApproveClick () {
      this.editType = 'approving'
    }
  },
  watch: {
    editType (to, from) {
      if (to === 'approve') {
        this.tabIndex = 1
      } else if (to === 'approving') {
        this.tabIndex = 0
        this.batchApprove()
      } else if (to === 'record') {
        this.tabIndex = 0
        this.note = null
        this.batchMessage = null
        this.checkedRows = []
        this.ids = []
        this.getRecords()
      }
    }
  }
}
</script>

<style>
#hongbao {
  padding: 12px;
}

.detail-buttons {
  padding-top: 10px;
}
</style>
